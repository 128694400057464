<template>
    <v-container>
      <v-row class="fill-height" align="center" justify="center" no-gutters>

        <!-- Loading -->
        <v-col cols="12">
          <v-row align="center" justify="center" class="fill-height" no-gutters>
            <v-progress-circular
              v-if="isLoading.main"
              :size="70"
              :width="7"
              color="primary"
              indeterminate>
            </v-progress-circular>
          </v-row>
        </v-col>


        <!-- Can reset -->
        <v-col cols="12" v-if="canReset">

          <h1 class="font-weight-bold text-center mt-6" v-if="!hasReset">{{$t('account.reset-password.title')}}</h1>

          <form v-if="!hasReset" class="mt-6" @submit.prevent>
            <v-text-field
              shaped
              :label="$t('account.reset-password.labels.password')"
              type="password"
              v-model="form.password"
              outlined
              required>
              <v-tooltip
                slot="append"
                bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">far fa-question-circle</v-icon>
                </template>
                <template v-for="(policy) in $t('account.reset-password.tooltips.password_rules')">
                  <div> - {{policy}}</div>
                </template>
              </v-tooltip>
            </v-text-field>

            <v-text-field
              shaped
              :label="$t('account.reset-password.labels.password_verification')"
              type="password"
              v-model="form.password_verification"
              outlined
              required>
            </v-text-field>
          </form>

          <v-alert
            v-if="!!error"
            :value="!!error"
            type="error"
            transition="scale-transition">
            {{error}}
          </v-alert>


          <v-row justify="center" v-if="!hasReset" class="mt-4" no-gutters>
            <Recaptcha ref="recaptcha" @verify="(response) => resetPassword(response)" />

            <v-btn
              color="primary"
              class="btn-primary"
              @click="executeRecaptcha"
              :loading="isLoading.btnResetPassword">
              {{$t('account.reset-password.buttons.submit')}}
            </v-btn>

          </v-row>

        </v-col>

        <!-- Has reset -->
        <v-col cols="12" v-if="hasReset">

          <v-row fill-height align="center" no-gutters>
            <v-col cols="12">
              <p class="text-center">
                <v-icon size="100" color="success">fal fa-check-circle</v-icon>
              </p>
            </v-col>

            <v-col cols="12" class="mt-4">
              <div class="text-center font-size-20 MontserratMedium" v-html="$t('account.reset-password.success')"/>
            </v-col>

            <v-col cols="12" class="mt-4 text-center">
              <v-btn class="btn-primary" color="primary" @click="() => $router.push({name: 'Login'})">{{$t('account.reset-password.buttons.login')}}</v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
</template>

<script>
  export default {

    name: "ResetPassword",

    components: {

      Recaptcha: () => import("@/components/Common/Recaptcha"),
    },

    data() {

      return {
        error: null,

        canReset: false,

        hasReset: false,

        form:
        {
          password: "",
          password_verification: ""
        },

        isLoading:
        {
          main: false,
          btnResetPassword: false
        }
      }
    },

    created() {

      this.$store.commit("pageHead/setTitle", this.$t('account.reset-password.pageTitle'));
    },

    mounted()
    {
      this.checkToken();
    },

    methods: {
      executeRecaptcha(event)
      {
        event.preventDefault();
        this.$refs.recaptcha.execute();
      },

      checkToken() {
        this.isLoading.main = true;

        this.$http.get(`/map/users/reset-password-token?token=${this.$route.query.token}`)
        .then(res =>
        {
          this.isLoading.main = false;
          this.canReset = true;
        })
        .catch(err =>
        {
          this.isLoading.main = false;
          this.$router.push({name: 'error', params: {status: "404"}});
        })
      },

      resetPassword(recaptcha)
      {
        this.error = null;

        if (this.form.password === '' || this.form.password_verification === '')
        {
          this.error = this.$t('account.reset-password.errors.EMPTY_FIELD');
          return;
        }

        if (this.form.password !== this.form.password_verification)
        {
          this.error = this.$t('account.reset-password.errors.PASSWORDS_NOT_MATCHING');
          return;
        }

        this.isLoading.btnResetPassword = true;


        this.$http.post(`/map/users/reset-password`,
        {
          token: this.$route.query.token,
          password: this.form.password,
          "g-recaptcha-response": recaptcha
        })
        .then(res =>
        {
          this.hasReset = true;
        })
        .catch(err =>
        {
          console.log(err.response);
          if (err.response.status === 400 && this.$t('account.reset-password.errors').hasOwnProperty(err.response.data.type))
            this.error = this.$t('account.reset-password.errors')[err.response.data.type];
          else
            this.error = this.$t('common.errors.500');
        })
        .finally(this.isLoading.btnResetPassword = false);
      }
    }
  }
</script>

<style scoped>

</style>